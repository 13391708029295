import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static get targets () {
    return ['modelSelector', 'recordSelector']
  }

  update () {
    console.log('url', this.url)
    console.log('requestParams', this.requestParams)
    this.loadContent()
  }

  loadContent () {
    const url = this.url
    const params = this.requestParams
    const request = `${url}?${params}`

    Rails.ajax({
      url: request,
      type: 'get',
      dataType: 'script',
      error: () => { alert('An error accrued while loading') }
    })
  }

  get requestParams () {
    var possibleParams = [
    `model=${this.selectedModel}`
    ]

    return possibleParams.join('&')
  }

  get url () {
    return this.data.get('url')
  }

  get selectedModel () {
    const el = this.modelSelectorTarget
    const value = el.options[el.selectedIndex].value

    return value
  }
}
