import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'share',
      'paymentVal',
      'paymentUsdVal'
    ]
  }

  static get values () {
    return {
      poVal: Number,
      poUsdVal: Number
    }
  }

  connect () {
    this.refresh()
  }

  refresh () {
    this.calculateTotalValue()
    this.calculateTotalUsdValue()
  }

  get shareRate () {
    return parseFloat(this.shareTarget.value / 100)
  }

  calculateTotalValue () {
    const paymentVal = this.paymentValTarget
    const poVal = this.poValValue
    const result = (poVal * this.shareRate)

    paymentVal.value = parseFloat(result).toFixed(2)
  }

  calculateTotalUsdValue () {
    const paymentUsdVal = this.paymentUsdValTarget
    const poUsdVal = this.poUsdValValue
    const result = (poUsdVal * this.shareRate)

    paymentUsdVal.value = parseFloat(result).toFixed(2)
  }
}
