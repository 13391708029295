// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Flatpickr from 'stimulus-flatpickr'
import { Dropdown, Modal, Tabs, Toggle, Slideover } from 'tailwindcss-stimulus-components'
// Popover

require('flatpickr/dist/flatpickr.css')

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('flatpickr', Flatpickr)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
// application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
