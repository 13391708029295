import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'row'
    ]
  }

  connect () {
    this.rowTargets.forEach((row) => {
      const text = this.buildRowContent(row)
      row.setAttribute('data-row-content', text)
    })
  }

  buildRowContent (row) {
    const columns = Array.from(row.children)
    const filteredColumn = columns.filter(i => !i.hasAttribute('data-search-ignore'))
    const values = filteredColumn.map(col => col.getAttribute('data-search-content') || col.innerText)
    return values.join(' ').replace(/ +(?= )/g, '').trim().toUpperCase()
  }

  search (e) {
    const query = e.target.value.toUpperCase()

    if (query.length > 0) {
      this.rowTargets.forEach(row => {
        var content = row.getAttribute('data-row-content')

        if (content.search(query) > -1) {
          row.classList.remove('hide')
        } else {
          row.classList.add('hide')
        }
      })
    } else {
      this.showAllItems()
    }
  }

  showAllItems () {
    this.rowTargets.forEach(i => i.classList.remove('hide'))
  }
}
