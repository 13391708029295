import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const id = this.element.id
    const url = this.url
    const token = this.token
    const model = this.model
    const field = $(`#${id}`)

    field.select2({
      placeholder: 'Start Typing or Select',
      allowClear: true,
      ajax: {
        cache: true,
        delay: 500,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        url: url,
        data: function (params) {
          var query = {
            q: params.term,
            model: model,
            page: params.page || 1
          }

          return query
        }
      }
    })

    if (this.selectedVal && this.selectedVal !== '') {
      var data = {
        id: this.selectedVal,
        text: this.selectedLabel
      }

      var newOption = new Option(data.text, data.id, true, true)
      field.append(newOption).trigger('change')
    }
  }

  get url () {
    return this.data.get('url')
  }

  get token () {
    return this.data.get('token')
  }

  get model () {
    return this.data.get('model')
  }

  get selectedVal () {
    return this.data.get('selected-val')
  }

  get selectedLabel () {
    return this.data.get('selected-label')
  }
}
