import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'selectedText',
      'selectedValue'
    ]
  }

  static get values () {
    return {
      params: Object
    }
  }

  refresh (e) {
    const selector = e.target
    const selectedValue = selector.value
    const selectedText = this.selectedDate(selector, selectedValue)

    this.selectedTextTargets.forEach((el) => {
      el.innerHTML = selectedText
    }, selectedText)

    this.selectedValueTargets.forEach((el) => {
      el.innerHTML = selectedValue
    }, selectedValue)
  }

  selectedDate (selector, selectedValue) {
    if (this.hasParamsValue) {
      const data = this.paramsValue
      return data[selectedValue]
    } else {
      return selector.options[selector.selectedIndex].text
    }
  }
}
