import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'container',
      'logoContainer',
      'collapseBtn',
      'collapseIcon',
      'mobileMenuBtn',
      'navLinkText',
      'subNavContainer',
      'containerSideBody'
    ]
  }

  connect () {
    // this._buildCollapse()
  }

  toggleMobile (e) {
    e.preventDefault()
    const container = this.containerTarget

    if (container.classList.contains('sidebar-navigation--expanded')) {
      this._closeMobile()
    } else {
      this._openMobile()
    }
  }

  // forceMobileClosure () {
  //   if (this._mobile) {
  //     this._closeMobile()
  //   }
  // }

  // toggleCollapse (e) {
  //   e.preventDefault()
  //   if (this._mobile) {
  //     this._closeMobile()
  //     return
  //   }

  //   const state = this._storageState

  //   // this default state is closed, so if no state is found,
  //   // we'll assume the user wants to close the sidebar
  //   if (state) {
  //     if (state === 'open') {
  //       this._closeCollapse()
  //     } else {
  //       this._openCollapse()
  //       this._logDesktopState('open')
  //     }
  //   } else {
  //     this._closeCollapse()
  //   }

  //   console.log('sidebar_state', this._storageState)
  // }

  // need to force the UI state based on the local storage state
  // if no state none, we default to closed
  // _buildCollapse () {
  //   if (this._mobile) {
  //     this._openCollapse()
  //     return
  //   }

  //   const state = this._storageState

  //   if (state === 'open') {
  //     this._openCollapse()
  //     this._logDesktopState('open')
  //   } else {
  //     this._closeCollapse()
  //   }
  // }

  _openMobile () {
    const container = this.containerTarget
    const containerSideBody = this.containerSideBodyTarget
    // this._openCollapse() // this sets up the side bar for mobile
    containerSideBody.classList.add('sidebar-navigation--side-body--expanded')
    container.classList.add('sidebar-navigation--expanded')
    // containerSideBody.classList.add('inset-0')
    // containerSideBody.classList.add('left-64')
    // containerSideBody.classList.remove('invisible')
    // containerSideBody.classList.remove('smo:opacity-0')
    // containerSideBody.classList.add('smo:opacity-50')
    // container.classList.remove('smo:opacity-0')
    // container.classList.remove('smo:-left-64')
    // container.classList.add('smo:opacity-100')
    // container.classList.add('smo:left-0')
  }

  _closeMobile () {
    const container = this.containerTarget
    const containerSideBody = this.containerSideBodyTarget
    containerSideBody.classList.remove('sidebar-navigation--side-body--expanded')
    container.classList.remove('sidebar-navigation--expanded')
    // containerSideBody.classList.remove('inset-0')
    // containerSideBody.classList.remove('left-64')
    // containerSideBody.classList.add('smo:opacity-0')
    // containerSideBody.classList.remove('smo:opacity-50')
    // containerSideBody.classList.remove('invisible')
    // container.classList.add('smo:opacity-0')
    // container.classList.add('smo:-left-64')
    // container.classList.remove('smo:opacity-100')
    // container.classList.remove('smo:left-0')
  }

  // _openCollapse () {
  //   // logo container
  //   const logoContainer = this.logoContainerTarget
  //   logoContainer.classList.remove('w-16')
  //   logoContainer.classList.add('w-64')

  //   // collapse icon spinner
  //   const collapseIcon = this.collapseIconTarget
  //   collapseIcon.classList.remove('transform-gpu')
  //   collapseIcon.classList.remove('rotate-180')

  //   // minify text links
  //   const LinkTextItems = this.navLinkTextTargets
  //   LinkTextItems.forEach(i => i.classList.remove('hidden'))

  //   // shrink sidebar side
  //   const sidebar = this.containerTarget
  //   sidebar.classList.remove('w-16')

  //   // disable tooltips
  //   const sidebarTooltips = Array.from(sidebar.querySelectorAll('.tooltip'))
  //   sidebarTooltips.forEach(i => i.classList.add('tooltip-disabled'))

  //   // change sub-nav menus
  //   const subNavContainers = this.subNavContainerTargets
  //   subNavContainers.forEach(i => i.classList.add('sub-menu-expanded'))
  //   subNavContainers.forEach(i => i.classList.remove('sub-menu-collapsed'))
  // }

  // _closeCollapse () {
  //   // logo container
  //   const logoContainer = this.logoContainerTarget
  //   logoContainer.classList.remove('w-64')
  //   logoContainer.classList.add('w-16')

  //   // collapse icon spinner
  //   const collapseIcon = this.collapseIconTarget
  //   collapseIcon.classList.add('transform-gpu')
  //   collapseIcon.classList.add('-rotate-180')

  //   // minify text links
  //   const LinkTextItems = this.navLinkTextTargets
  //   LinkTextItems.forEach(i => i.classList.add('hidden'))

  //   // shrink sidebar side
  //   const sidebar = this.containerTarget
  //   sidebar.classList.add('w-16')

  //   // activate tooltips
  //   const sidebarTooltips = Array.from(sidebar.querySelectorAll('.tooltip'))
  //   sidebarTooltips.forEach(i => i.classList.remove('tooltip-disabled'))

  //   // change sub-nav menus
  //   const subNavContainers = this.subNavContainerTargets
  //   subNavContainers.forEach(i => i.classList.remove('sub-menu-expanded'))
  //   subNavContainers.forEach(i => i.classList.add('sub-menu-collapsed'))

  //   this._logDesktopState('closed')
  // }

  // _logDesktopState (state) {
  //   const _state = state
  //   window.localStorage.setItem(this._storageKey, _state)
  // }

  // get _deviceSize () {
  //   const el = this.mobileMenuBtnTarget.parentElement
  //   const styles = window.getComputedStyle(el)

  //   if (styles.visibility === 'hidden') {
  //     return 'desktop'
  //   } else {
  //     return 'mobile'
  //   }
  // }

  // get _mobile () {
  //   return this._deviceSize === 'mobile'
  // }

  // get _desktop () {
  //   return this._deviceSize === 'desktop'
  // }

  // get _storageKey () {
  //   return 'side-navigation-collapse'
  // }

  // get _storageState () {
  //   return window.localStorage.getItem(this._storageKey)
  // }
}
