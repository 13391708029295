import { Controller } from 'stimulus'
import '@uppy/core/dist/style.css'
import '@uppy/status-bar/dist/style.css'
import {
  Core,
  FileInput,
  Informer,
  // ProgressBar,
  StatusBar,
  ThumbnailGenerator,
  AwsS3
} from 'uppy'

export default class extends Controller {
  static get targets () {
    return [
      'previewContainer',
      'previewImage',
      'hiddenInput',
      'input',
      'progress',
      'uploadButton',
      'informer',
      'fileLink'
    ]
  }

  connect () {
    const fileInput = this.inputTarget
    this.fileUpload(fileInput)
  }

  fileUpload (fileInput) {
    const formGroup = fileInput.parentNode
    const hiddenInput = this.hiddenInputTarget
    const imagePreview = this.previewImageTarget
    const progressBarElement = this.progressTarget
    const uploadButton = this.uploadButtonTarget
    const informer = this.informerTarget
    const fileLink = this.fileLinkTarget
    const ctaText = this.ctaText
    const token = this.token

    // remove our file input in favour of Uppy's
    formGroup.removeChild(fileInput)

    const uppy = Core({
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: (1024 * 1024 * 1024) // 1GB
      }
    })
      .use(FileInput, {
        target: uploadButton,
        locale: {
          strings: {
            chooseFiles: ctaText
          }
        }
      })
      .use(Informer, {
        target: informer
      })
      // .use(ProgressBar, {
      //   target: progressBarElement
      // })
      .use(StatusBar, {
        target: progressBarElement,
        hideUploadButton: true,
        hideAfterFinish: false
      })
      // .use(cors({
      //   methods: ['OPTIONS', 'GET', 'POST', 'PATCH', 'PUT'],
      //   allowedHeaders: ['Content-Type', 'Authorization', 'Uppy-Versions', 'Accept'],
      //   exposedHeaders: ['Access-Control-Allow-Headers']
      // }))
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600
      })
      .use(AwsS3, {
        companionUrl: '/', // will call the presign endpoint on `/s3/params`
        timeout: 300 * 1000, // 5 minutes
        companionHeaders: {
          Authorization: `Bearer ${token}`
        }
      })

    uppy.on('thumbnail:generated', (file, preview) => {
      // show preview of the image using URL from thumbnail generator
      imagePreview.src = preview
    })

    uppy.on('upload-success', (file, response) => {
      const url = response.uploadURL
      const fileName = file.name
      const a = fileLink
      a.href = url
      a.innerText = fileName
      a.classList.remove('hide')

      // construct uploaded file data in the format that Shrine expects
      const uploadedFileData = {
        id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type
        }
      }

      // set hidden field value to the uploaded file data so that it's submitted
      // with the form as the attachment
      hiddenInput.value = JSON.stringify(uploadedFileData)
    })
  }

  get ctaText () {
    return this.data.get('cta') || 'Upload File'
  }

  get token () {
    return this.data.get('token')
  }
}
