import { Controller } from 'stimulus'

export default class extends Controller {
  toggle (e) {
    e.preventDefault()
    const element = this.currenElement(e)
    const toggleTarget = element.dataset.toggleTarget

    this.toggleCSS(element, toggleTarget)
  }

  toggleCSS (element, target) {
    const klass = this.toggleClass
    const containers = this.toggleContainers

    containers.forEach((i) => {
      console.log('i', i)
      if (i.dataset.toggleName === target) {
        i.classList.remove(klass)
      } else {
        i.classList.add(klass)
      }
    }, target)
  }

  get toggleClass () {
    return this.data.has('class') ? this.data.get('class') : 'hide'
  }

  get toggleContainers () {
    return Array.from(this.element.querySelectorAll('[data-toggle-name'))
  }

  currenElement (e) {
    const element = e.target

    if (element.dataset.toggleTarget) {
      return element
    } else {
      return element.closest('[data-toggle-target]')
    }
  }
}
