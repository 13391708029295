import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'purchaseValue',
      'currency',
      'currencyRate',
      'usdPurchaseValue',
      'purchaseSavings',
      'usdSavings',
      'rebatePercentage',
      'rebatePurchaseValue',
      'rebateUsdValue',
      'vatRate',
      'vatValue',
      'usdVatValue',
      'selectedCurrency',
      'rawVat',
      'vatScope',
      'subtotalWithVat'
    ]
  }

  connect () {
    this.toggleManualVat()
    this.refresh()
  }

  refresh () {
    const form = this
    setTimeout(function () {
      form.assignSelectedCurrency()
      form.calculateUsdPurchaseValue()
      form.calculateUsdSavings()
      form.calculateRebateValue()
      form.calculaterebateUsdValue()
      form.calculateVatValue()
      form.calculateUsdVatValue()
      form.calculateSubtotalWithVat()
    }, 50)
  }

  toggleManualVat () {
    const vatRate = this.vatRateTarget
    const vatValue = this.vatValueTarget

    if (this.rawVatValueRequired) {
      vatRate.disabled = true
      vatValue.disabled = false
    } else {
      vatRate.disabled = false
      vatValue.disabled = true
      this.calculateVatValue()
      this.calculateUsdVatValue()
      this.calculateSubtotalWithVat()
    }
  }

  get rawVatValueRequired () {
    if (this.hasRawVatTarget) {
      return this.rawVatTarget.checked
    } else {
      return false
    }
  }

  get rebateRate () {
    return parseFloat(this.rebatePercentageTarget.value / 100)
  }

  assignSelectedCurrency () {
    const currencyField = this.currencyTarget
    const currency = currencyField.options[currencyField.selectedIndex].text
    const selectedCurrencies = this.selectedCurrencyTargets

    selectedCurrencies.forEach((i) => {
      i.innerText = currency
    }, currency)
  }

  calculateUsdPurchaseValue () {
    const usdPurchaseValue = this.usdPurchaseValueTarget
    const purchaseValue = parseFloat(this.purchaseValueTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const result = (purchaseValue * currencyRate)

    usdPurchaseValue.value = parseFloat(result).toFixed(2)
  }

  calculateUsdSavings () {
    const usdSavings = this.usdSavingsTarget
    const purchaseSavings = parseFloat(this.purchaseSavingsTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const result = (purchaseSavings * currencyRate)

    usdSavings.value = parseFloat(result).toFixed(2)
  }

  calculateRebateValue () {
    const rebatePurchaseValue = this.rebatePurchaseValueTarget
    const purchaseValue = parseFloat(this.purchaseValueTarget.value)
    const result = (purchaseValue * this.rebateRate)

    rebatePurchaseValue.value = parseFloat(result).toFixed(2)
  }

  calculaterebateUsdValue () {
    const rebateUsdValue = this.rebateUsdValueTarget
    const rebatePurchaseValue = parseFloat(this.rebatePurchaseValueTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const result = (rebatePurchaseValue * currencyRate)

    rebateUsdValue.value = parseFloat(result).toFixed(2)
  }

  calculateVatValue () {
    if (this.rawVatValueRequired) return
    if (!this.hasVatScopeTarget) return

    const vatScope = this.vatScopeTarget.value

    if (vatScope === 'inclusive') {
      this.calculateInclusiveVatValue()
    } else if (vatScope === 'exclusive') {
      this.calculateExclusiveVatValue()
    } else {
      this.calculateNaVatValue()
    }
  }

  calculateInclusiveVatValue () {
    if (!this.vatValueTarget) return
    if (!this.vatRateTarget) return

    const vatValue = this.vatValueTarget
    const vatRate = parseFloat(this.vatRateTarget.value)
    const purchaseValue = parseFloat(this.purchaseValueTarget.value)
    const vatInclusiveRate = parseFloat(vatRate / (100.0 + vatRate))
    const result = (purchaseValue * vatInclusiveRate)

    vatValue.value = parseFloat(result).toFixed(2)
  }

  calculateExclusiveVatValue () {
    if (!this.vatValueTarget.value) return
    if (!this.vatRateTarget) return

    const vatValue = this.vatValueTarget
    const vatRate = parseFloat(this.vatRateTarget.value / 100)
    const purchaseValue = parseFloat(this.purchaseValueTarget.value)
    const result = (purchaseValue * vatRate)

    vatValue.value = parseFloat(result).toFixed(2)
  }

  calculateNaVatValue () {
    if (!this.vatValueTarget) return
    if (!this.vatRateTarget) return

    const vatValue = this.vatValueTarget
    const vatRate = this.vatRateTarget

    vatValue.value = 0
    vatRate.value = 0
  }

  calculateUsdVatValue () {
    const usdVatValue = this.usdVatValueTarget

    if (!this.vatValueTarget.value) return

    const vatValue = parseFloat(this.vatValueTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const result = (vatValue * currencyRate)

    usdVatValue.value = parseFloat(result).toFixed(2)
  }

  calculateSubtotalWithVat () {
    if (!this.hasSubtotalWithVatTarget) return
    if (!this.hasPurchaseValueTarget) return
    if (!this.hasVatValueTarget) return
    if (!this.hasVatScopeTarget) return

    const subtotalWithVat = this.subtotalWithVatTarget
    const vatScope = this.vatScopeTarget.value
    const purchaseValue = parseFloat(this.purchaseValueTarget.value)
    const vatValue = parseFloat(this.vatValueTarget.value)

    if (vatScope === 'inclusive') {
      subtotalWithVat.value = purchaseValue
    } else {
      subtotalWithVat.value = purchaseValue + vatValue
    }
  }
}
