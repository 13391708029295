import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return ['option']
  }

  selectOption (e) {
    e.preventDefault()
    const element = this.currenElement(e)

    this.toggleCSS(element)
    this.toggleStatus(element)

    const event = new CustomEvent('refresh-data')
    window.dispatchEvent(event)
  }

  toggleCSS (element) {
    const klass = this.toggleClass

    this.options.forEach(i => i.classList.remove(klass))
    element.classList.add(klass)
  }

  toggleStatus (element) {
    this.options.forEach(i => i.dataset.status = 'open')
    element.dataset.status = 'active'
  }

  get options () {
    return this.optionTargets
  }

  get toggleClass () {
    return this.data.get('class')
  }

  currenElement (e) {
    const element = e.target

    if (element.dataset.target && element.dataset.target.includes('option-toggler.option')) {
      return element
    } else {
      return element.closest('[data-target*="option-toggler.option"]')
    }
  }
}
