import { Controller } from 'stimulus'
import moment from 'moment'
// moment().format()

export default class extends Controller {
  static get targets () {
    return [
      'invoiceDate',
      'paymentTerms',
      'paymentDueDate',
      'value',
      'discountValue',
      'netValue',
      'vatRate',
      'vatValue',
      'additionalValue',
      'withholdingTaxPercentage',
      'withholdingTaxValue',
      'rebatePercentage',
      'rebateValue',
      'currencyRate',
      'exchangedValue',
      'totalValue',
      'netIncomeValue'
    ]
  }

  connect () {
    this.calculatepaymentDueDate()
    this.refresh()
  }

  refresh () {
    this.calculateNetValue()
    this.calculateVatValue()
    this.calculateWithholdingTaxValue()
    this.calculateRebateValue()
    this.calculateTotalValue()
    this.calculateExchangedValue()
    this.calculateNetTotalValue()
  }

  get vatRate () {
    return parseFloat(this.vatRateTarget.value / 100)
  }

  get netValue () {
    return parseFloat(this.netValueTarget.value)
  }

  get rebateRate () {
    return parseFloat(this.rebatePercentageTarget.value / 100)
  }

  get rebateValue () {
    return parseFloat(this.rebateValueTarget.value)
  }

  get withholdingTaxRate () {
    return parseFloat(this.withholdingTaxPercentageTarget.value / 100)
  }

  get withholdingTaxValue () {
    return parseFloat(this.withholdingTaxValueTarget.value)
  }

  calculatepaymentDueDate () {
    const invoiceDate = this.invoiceDateTarget.value
    const paymentTerms = this.paymentTermsTarget.value
    const paymentDueDate = this.paymentDueDateTarget
    let paymentTermDays = 0

    if (paymentTerms === 'immediate') {
      paymentTermDays = 1
    } else {
      paymentTermDays = parseInt(paymentTerms.split('_')[1])
    }

    const dueDate = moment(invoiceDate, 'DD-MM-YYYY').utc().add(paymentTermDays, 'days').format('DD-MM-YYYY')
    paymentDueDate.value = dueDate
  }

  calculateNetValue () {
    const netValue = this.netValueTarget
    const value = parseFloat(this.valueTarget.value)
    const discountValue = parseFloat(this.discountValueTarget.value)
    const result = (value - discountValue)

    netValue.value = parseFloat(result).toFixed(2)
  }

  calculateVatValue () {
    const vatValue = this.vatValueTarget
    const value = this.netValue
    const result = (value * this.vatRate)

    vatValue.value = parseFloat(result).toFixed(2)
  }

  calculateWithholdingTaxValue () {
    const withholdingTaxValue = this.withholdingTaxValueTarget
    const value = this.netValue
    const result = (value * this.withholdingTaxRate)

    withholdingTaxValue.value = parseFloat(result).toFixed(2)
  }

  calculateRebateValue () {
    const rebateValue = this.rebateValueTarget
    const value = this.netValue
    const result = (value * this.rebateRate)

    rebateValue.value = parseFloat(result).toFixed(2)
  }

  calculateTotalValue () {
    const totalValue = this.totalValueTarget
    const netValue = this.netValue
    const additionalValue = parseFloat(this.additionalValueTarget.value)
    const vatValue = parseFloat(this.vatValueTarget.value)
    const result = (netValue + additionalValue + vatValue)

    totalValue.value = parseFloat(result).toFixed(2)
  }
  // const withholdingTaxValue = parseFloat(this.withholdingTaxValueTarget.value)

  calculateExchangedValue () {
    const exchangedValue = this.exchangedValueTarget
    const totalValue = parseFloat(this.totalValueTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const result = (totalValue * currencyRate)

    exchangedValue.value = parseFloat(result).toFixed(2)
  }

  calculateNetTotalValue () {
    const netIncomeValue = this.netIncomeValueTarget
    const netValue = this.netValue
    const rebateValue = this.rebateValue
    const result = (netValue - rebateValue)

    netIncomeValue.value = parseFloat(result).toFixed(2)
  }
}
