import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'formData',
      'severityOption',
      'likelihoodOption',
      'riskRating',
      'riskRatingScore',
      'riskRatingLabel',
      'likelihoodScore',
      'likelihoodLabel',
      'severityScore',
      'severityLabel'
    ]
  }

  connect () {
    this.updateLikelihood()
    this.updateSeverity()
    this.tallyTotal()
  }

  logSelection (key, value) {
    var json = this.formData
    json[key] = value

    this.formDataTarget.value = JSON.stringify(json)
  }

  refresh () {
    const riskRating = this.riskRatingTarget

    var score = this.updateLikelihood()
    this.logSelection('likelihood_rating', score)

    var score = this.updateSeverity()
    this.logSelection('severity_rating', score)

    this.storeSeverities()

    var score = this.tallyTotal()
    riskRating.value = score
  }

  updateLikelihood () {
    const likelihoodScore = this.likelihoodScoreTarget
    const likelihoodLabel = this.likelihoodLabelTarget
    const likelihoodButton = likelihoodLabel.parentNode
    const score = this.fetchLikelihoodScore
    const label = score == 0 ? 'Please select' : this.convertLikelihood(score)

    likelihoodLabel.innerText = label
    likelihoodScore.innerText = score
    likelihoodButton.dataset.riskSelectorRating = score // set color of likelihood button

    return score
  }

  updateSeverity () {
    const severityScore = this.severityScoreTarget
    const severityLabel = this.severityLabelTarget
    const severityButton = severityLabel.parentNode
    const score = this.fetchSeverityScore
    const label = score == 0 ? 'Please select' : this.convertSeverity(score)

    severityLabel.innerText = label
    severityScore.innerText = score
    severityButton.dataset.riskSelectorRating = score // set color of severity button

    return score
  }

  popup (e) {
    e.preventDefault()
  }

  storeSeverities () {
    var options = this.severityOptions

    options.forEach((i) => {
      if (i.dataset.status == 'active') {
        var rating = i.dataset.rating
        var categoryId = i.dataset.category
        var name = 'severity_category_' + categoryId

        this.logSelection(name, rating)
      }
    }, this)
  }

  tallyTotal () {
    const riskRatingScore = this.riskRatingScoreTarget
    const riskRatingButton = riskRatingScore.parentNode
    const json = this.formData
    const likelihood = json.likelihood_rating ? json.likelihood_rating : 0
    const severity = json.severity_rating ? json.severity_rating : 0
    const score = likelihood * severity

    riskRatingScore.innerText = score
    riskRatingButton.dataset.riskSelectorRating = this.riskRatingScoreCSS(score) // set color of likelihood button

    return score
  }

  convertSeverity (key) {
    return this.severityData[key]
  }

  convertLikelihood (key) {
    return this.likelihoodData[key]
  }

  get fetchLikelihoodScore () {
    const options = this.likelihoodOptions
    const el = options.find(i => i.dataset.status === 'active')
    const score = parseInt(el.dataset.rating)
    return score
  }

  get fetchSeverityScore () {
    var options = this.severityOptions
    var ratings = options.map(i => i.dataset.status === 'active' ? parseInt(i.dataset.rating) : 0)
    var score = Math.max(...ratings)
    return score
  }

  get likelihoodOptions () {
    return this.likelihoodOptionTargets
  }

  get severityOptions () {
    return this.severityOptionTargets
  }

  get likelihoodData () {
    return JSON.parse(this.data.get('likelihood-data'))
  }

  get severityData () {
    return JSON.parse(this.data.get('severity-data'))
  }

  get formData () {
    var raw = this.formDataTarget.value
    return JSON.parse(raw)
  }

  riskRatingScoreCSS (rank) {
    const score = parseInt(rank)

    if (score <= 3) { return 1 } else if (score <= 6) { return 2 } else if (score <= 9) { return 3 } else if (score <= 16) { return 4 } else if (score > 16) { return 5 }
  }
}
