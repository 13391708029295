import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return ['container']
  }

  setFinanceDefaults (e) {
    e.preventDefault()
    const financialFields = this.financialFields

    this.setFields(financialFields)
  }

  setUtilisationDefaults (e) {
    e.preventDefault()
    const utilisationFields = this.utilisationFields

    this.setFields(utilisationFields)
  }

  setStatusDefaults (e) {
    e.preventDefault()
    const statusFields = this.statusFields

    this.setFields(statusFields)
  }

  setFields (fields) {
    const allFields = this.allFields

    allFields.forEach(field => {
      var input = document.querySelector(`input[value=${field}]`)
      input.checked = fields.includes(field)
    }, fields)
  }

  get allFields () {
    return [
      'id',
      'type',
      'serial_number',
      'second_serial_number',
      'model',
      'date_of_manufacture',
      'asset_status',
      'operating_status',
      'maintenance_status',
      'project',
      'business_line',
      'sub_business_line',
      'office',
      'permanent_parent',
      'notes',
      'fin_code',
      'financial_status',
      'po_number',
      'equipment_user_tags',
      'gross_book_value',
      'net_book_value',
      'depreciation_start_date',
      'depreciation_period',
      'monthly_depreciation',
      'depreciation_end_date',
      'reserved_project',
      'asset_owner',
      'physical_location',
      'asset_received',
      'coc',
      'quality_book',
      'verifier',
      'verified_at',
      'lease',
      'expired_maintenance_grade',
      'creator',
      'reviewer',
      'reviewed_at',
      'approver',
      'approved_at',
      'locked',
      'lock_notes',
      'reject_notes',
      'created_at',
      'updated_at',
      'finance_name',
      'asset_category',
      'budget_group',
      'custom_duty',
      'sales_tax',
      'logistics_cost',
      'currency',
      'currency_rate',
      'purchase_value',
      'usd_purchase_value',
      'sale_writeoff_value',
      'finance_notes'
    ]
  }

  get financialFields () {
    return [
      'id',
      'type',
      'serial_number',
      'second_serial_number',
      'model',
      'po_number',
      'equipment_user_tags',
      'fin_code',
      'financial_status',
      'date_of_manufacture',
      'asset_status',
      'operating_status',
      'maintenance_status',
      'lease',
      'project',
      'business_line',
      'office',
      'physical_location',
      'gross_book_value',
      'net_book_value',
      'depreciation_start_date',
      'depreciation_period',
      'monthly_depreciation',
      'depreciation_end_date',
      'custom_duty',
      'sales_tax',
      'logistics_cost',
      'currency',
      'currency_rate',
      'purchase_value',
      'usd_purchase_value',
      'reserved_project',
      'asset_owner',
      'asset_category',
      'finance_notes',
      'notes'
    ]
  }

  get utilisationFields () {
    return [
      'id',
      'type',
      'serial_number',
      'second_serial_number',
      'model',
      'date_of_manufacture',
      'asset_status',
      'operating_status',
      'maintenance_status',
      'project',
      'equipment_user_tags',
      'business_line',
      'sub_business_line',
      'office',
      'physical_location',
      'financial_status',
      'gross_book_value',
      'net_book_value',
      'reserved_project',
      'asset_owner',
      'coc',
      'quality_book',
      'lease',
      'expired_maintenance_grade',
      'notes'
    ]
  }

  get statusFields () {
    return [
      'id',
      'type',
      'serial_number',
      'second_serial_number',
      'model',
      'asset_status',
      'operating_status',
      'maintenance_status',
      'financial_status',
      'project',
      'business_line',
      'office',
      'permanent_parent',
      'po_number',
      'equipment_user_tags',
      'gross_book_value',
      'net_book_value',
      'reserved_project',
      'asset_owner',
      'physical_location',
      'asset_received',
      'coc',
      'quality_book',
      'verifier',
      'verified_at',
      'lease',
      'expired_maintenance_grade',
      'notes'
    ]
  }
}
