import { Controller } from 'stimulus'
import moment from 'moment'

export default class extends Controller {
  static get targets () {
    return [
      'purchaseValue',
      'currencyRate',
      'usdPurchaseValue',
      'customDuty',
      'salesTax',
      'logisticsCost',
      'grossBookValue',
      'depreciationStartDate',
      'depreciationEndDate',
      'depreciationPeriod',
      'monthlyDepreciation'
    ]
  }

  connect () {
    this.refresh()
  }

  refresh () {
    this.calculateFinance()
    this.calculateDepreciation()
  }

  calculateFinance () {
    const purchaseValue = parseFloat(this.purchaseValueTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const customDuty = parseFloat(this.customDutyTarget.value)
    const salesTax = parseFloat(this.salesTaxTarget.value)
    const logisticsCost = parseFloat(this.logisticsCostTarget.value)
    const grossBookValue = this.grossBookValueTarget
    const usdPurchaseValue = this.usdPurchaseValueTarget
    const usdConvertValue = parseFloat(purchaseValue * currencyRate)
    const supportCost = parseFloat(customDuty + salesTax + logisticsCost)
    const total = parseFloat(usdConvertValue + supportCost)

    usdPurchaseValue.value = parseFloat(usdConvertValue).toFixed(2)
    grossBookValue.value = parseFloat(total).toFixed(2)
  }

  calculateDepreciation () {
    const grossBookValue = parseFloat(this.grossBookValueTarget.value)
    const depreciationStartDate = this.depreciationStartDateTarget.value
    const depreciationEndDate = this.depreciationEndDateTarget
    const depreciationPeriod = parseInt(this.depreciationPeriodTarget.value)
    const monthlyDepreciation = this.monthlyDepreciationTarget

    if (grossBookValue > 0) {
      monthlyDepreciation.value = parseFloat(grossBookValue / depreciationPeriod).toFixed(2)
    }

    if (depreciationStartDate && depreciationPeriod > 0) {
      var dateNumber = moment(depreciationStartDate, 'DD-MM-YYYY').utc().add(depreciationPeriod, 'months').format('DD-MM-YYYY')
      depreciationEndDate.value = dateNumber
    }
  }
}
