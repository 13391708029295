import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static get targets () {
    return ['button']
  }

  open (e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    this.openReveal()
    this.loadContent()
  }

  openReveal () {
    // have to use jquery here
    $(`#${this.buttonTarget.dataset.open}`).modal('show')
  }

  loadContent () {
    const { url, container } = this.buttonTarget.dataset
    const containerBody = document.getElementById(container)

    if (containerBody) {
      containerBody.innerHTML = '<div class="text-center padding-8">Loading...</div>'

      Rails.ajax({
        url,
        type: 'get',
        dataType: 'script',
        error: () => { alert('An error accrued while loading') }
      })
    } else {
      console.error(`could not locate #${container}`)
    }
  }
}
