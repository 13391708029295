import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static get targets () {
    return [
      'similarModelsList'
    ]
  }

  static get values () {
    return {
      url: String,
      params: String,
      token: String
    }
  }

  search (e) {
    const query = e.detail.text
    const url = this.urlValue
    const token = this.tokenValue
    const params = this.paramsValue
    const request = `${url}?${params}&q=${query}`

    Rails.ajax({
      url: request,
      type: 'get',
      dataType: 'json',
      accept: 'json',
      success: (json) => { this.renderSuccess(json) },
      error: () => { alert('An error accrued while loading') },
      beforeSend (xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        options.data = JSON.stringify(options.data)
        return true
      }
    })
  }

  renderSuccess (json) {
    const lists = this.similarModelsListTargets
    const items = Array.from(json.results)

    lists.forEach((list) => {
      list.innerHTML = null
      this.updateList(list, items)
    })
  }

  updateList (list, items) {
    items.forEach((i) => {
      var li = document.createElement('li')
      var text = document.createTextNode(i.text)
      li.appendChild(text)
      li.setAttribute('class', 'list-group-item')
      list.appendChild(li)
    })
  }
}
