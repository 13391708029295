import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'duration',
      'dateRange'
    ]
  }

  connect () {
    this.refresh()
  }

  refresh () {
    const duration = this.durationTarget.value
    const dateRange = this.dateRangeTarget

    if (duration === 'temporary' || duration === 'emergency') {
      dateRange.classList.remove('hide')
    } else {
      dateRange.classList.add('hide')
    }
  }
}
