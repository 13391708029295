import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'value',
      'vatRate',
      'vatValue',
      'additionalValue',
      'currencyRate',
      'totalValue',
      'exchangedValue'
    ]
  }

  connect () {
    this.refresh()
  }

  refresh () {
    this.calculateVatValue()
    this.calculateTotalValue()
    this.calculateExchangedValue()
  }

  get vatRate () {
    return parseFloat(this.vatRateTarget.value / 100)
  }

  calculateVatValue () {
    const vatValue = this.vatValueTarget
    const value = parseFloat(this.valueTarget.value)
    const result = (value * this.vatRate)

    vatValue.value = parseFloat(result).toFixed(2)
  }

  calculateTotalValue () {
    const totalValue = this.totalValueTarget
    const value = parseFloat(this.valueTarget.value)
    const vatValue = parseFloat(this.vatValueTarget.value)
    const additionalValue = parseFloat(this.additionalValueTarget.value)
    const result = (value + additionalValue + vatValue)

    totalValue.value = parseFloat(result).toFixed(2)
  }

  calculateExchangedValue () {
    const exchangedValue = this.exchangedValueTarget
    const totalValue = parseFloat(this.totalValueTarget.value)
    const currencyRate = parseFloat(this.currencyRateTarget.value)
    const result = (totalValue * currencyRate)

    exchangedValue.value = parseFloat(result).toFixed(2)
  }
}
