import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return ['container']
  }

  toggle (e) {
    e.preventDefault()
    this.toggleCSS()
  }

  toggleCSS (element, target) {
    const klass = this.toggleClass
    const containers = this.collapseContainers

    containers.forEach((i) => {
      i.classList.toggle(klass)
    })
  }

  get toggleClass () {
    return this.data.has('class') ? this.data.get('class') : 'hide'
  }

  get collapseContainers () {
    return this.containerTargets
  }
}
