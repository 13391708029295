import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'currencyRate',
      'currencyNameSelector',
      'currencyIdSelector'
    ]
  }

  static get values () {
    return {
      params: Array,
      direction: String
    }
  }

  connect () {
    this.refresh()
  }

  refresh () {
    this.updateUsingName()
    this.updateUsingId()
  }

  updateUsingName () {
    if (!this.hasCurrencyNameSelectorTarget) return

    const params = this.paramsValue
    const direction = this.directionValue
    const currencySelector = this.currencyNameSelectorTarget
    const selected = currencySelector.options[currencySelector.selectedIndex].text

    if (!selected) return

    const currency = params.find(i => i.name === selected)
    let value = 0.0

    if (currency) {
      value = currency[direction]
    }

    this.assignDefaultCurrencyRate(value)
  }

  updateUsingId () {
    if (!this.hasCurrencyIdSelectorTarget) return

    const params = this.paramsValue
    const direction = this.directionValue
    const currencySelector = this.currencyIdSelectorTarget
    const selected = parseInt(currencySelector.value)

    if (!selected) return

    const currency = params.find(i => i.id === selected)
    let value = 0.0

    if (currency) {
      value = currency[direction]
    }

    this.assignDefaultCurrencyRate(value)
  }

  assignDefaultCurrencyRate (value) {
    if (!this.hasCurrencyRateTarget) return

    const currencyRate = this.currencyRateTarget

    currencyRate.value = value
  }
}
