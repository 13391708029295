import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'value',
      'usdValue',
      'vatValue'
    ]
  }

  static get values () {
    return {
      vatRate: Number,
      currencyRate: Number
    }
  }

  connect () {
    this.refresh()
  }

  refresh () {
    this.calculateUsdValue()
    this.calculateVatValue()
  }

  get vatRate () {
    return parseFloat(this.vatRateValue / 100)
  }

  get currencyRate () {
    return parseFloat(this.currencyRateValue)
  }

  calculateUsdValue () {
    const usdValue = this.usdValueTarget
    const value = parseFloat(this.valueTarget.value)
    const result = (value * this.currencyRate)

    usdValue.innerText = parseFloat(result).toFixed(2)
  }

  calculateVatValue () {
    const vatValue = this.vatValueTarget
    const value = parseFloat(this.valueTarget.value)
    const result = (value * this.vatRate)

    vatValue.innerText = parseFloat(result).toFixed(2)
  }
}
