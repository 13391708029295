import { Turbo } from '@hotwired/turbo-rails'
import 'controllers'

require('@rails/ujs').start()

// styles
require('new_styles/app.scss')

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.Turbo = Turbo
