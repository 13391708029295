import { Controller } from 'stimulus'
import TomSelect from 'tom-select/dist/js/tom-select.complete.js'
import 'tom-select/dist/css/tom-select.min.css'

export default class extends Controller {
  static get values () {
    return {
      type: String
    }
  }

  connect () {
    const field = this.element
    const config = {
      plugins: {
        no_backspace_delete: {},
        dropdown_input: {},
        // checkbox_options: {},
        remove_button: {
          title: 'Remove this item'
        },
        clear_button: {
          title: 'Remove all selected options'
        }
      }
    }

    new TomSelect(field, config)
  }
}
