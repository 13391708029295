import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'container',
      'template'
    ]
  }

  add (e) {
    e.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
    const event = new CustomEvent('refresh-data')
    window.dispatchEvent(event)
  }

  remove (e) {
    e.preventDefault()
    const item = e.target.closest('.nested-fields')
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
    const event = new CustomEvent('refresh-data')
    window.dispatchEvent(event)
  }
}
